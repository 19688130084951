<script lang="ts">
  import { getString } from 'modules/lib';
  import { onMount } from 'svelte'

  export let contextid: number
  export let sesskey: string
  export let url: string
  export let getClasses: (isComplete: boolean) => string
  export let labelIncomplete: string
  export let labelComplete: string

  let classes: string
  $: classes = getClasses(isComplete)

  let isLoading: boolean = true
  let isComplete: boolean
  let icon: string
  $: icon = isComplete ? 'check-square' : 'square-o'

  async function handleClick(e) {
    return isComplete ? setIncomplete() : setComplete()
  }

  async function sendActionRequest(action: string) {
    const data = new FormData()

    data.append('contextid', `${contextid}`)
    data.append('sesskey', sesskey)
    data.append('action', action)

    const response = await fetch(url, {
      method: 'POST',
      body: data
    })

    if (response.ok) {
      const json = await response.json()

      if (!json?.error) {
        return json
      } else {
        throw new Error(json.error)
      }
    }
  }

  async function getCompletion() {
    const data = await sendActionRequest('get_iscomplete')

    if (data === true) {
      isComplete = true
    }
    else if (data === false) {
      isComplete = false
    }
  }

  async function setComplete() {
    isComplete = true
    await sendActionRequest('set_complete')
  }

  async function setIncomplete() {
    isComplete = false
    await sendActionRequest('set_incomplete')
  }

  onMount(async () => {
    await getCompletion()
    isLoading = false
  })
</script>

<a class:text-muted={isLoading} class={classes} href="#selfcompletion" on:click={handleClick}>
  <i class={`fa fa-${icon}`}></i>
  {
    isComplete ? labelComplete : labelIncomplete
  }
</a>
